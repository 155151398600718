import { useEffect, useRef, useState } from "react";
import MaterialTable from "material-table";
import { admin_api, api } from "../services";
import hp from "../services/hp";
import { Switch, Typography } from "@material-ui/core";
import { format } from "date-fns";
import moment from "moment/moment";
import * as XLSX from "xlsx";


function Page({ url, session_name, thumbnail_key, percent_url }) {
  const tableRef = useRef();

  const [thumbnail_img, setThumbnailImg] = useState("");
  const [percent, setPercent] = useState(0);
  const [input_key, setInputKey] = useState(Math.random().toString(36));
  const [total, setTotal] = useState(0);
  const [autoRefresh, setAutoRefresh] = useState(false);
  const [config, setConfig] = useState(null)

  useEffect(() => {
    onStart();

    if (autoRefresh) {
      let auto_refresh_interval = setInterval(() => {
        tableRef?.current?.onQueryChange();
        onStart();
      }, 10000);

      return () => {
        clearInterval(auto_refresh_interval);
      };
    }
  }, [autoRefresh]);

  const onStart = async () => {
    try {
      const config_res = await admin_api.get('config')
      const config = config_res.data
      let _config = {}
      config.forEach((c) => {
        _config[c.key] = c.value
      })
      setConfig(_config)

      let percent_res = await admin_api.get(percent_url);
      setPercent(percent_res.data);

      if (localStorage.getItem(thumbnail_key)) {
        setThumbnailImg(localStorage.getItem(thumbnail_key));
      } else {
        let thumbnail_img_res = await api.get(`config/${thumbnail_key}`);
        setThumbnailImg(thumbnail_img_res.data.value);
      }
     
    } catch (err) {
      console.log(err)
    }
  };

  const onAttach = async (data, fileInfo) => {
    if (data.length == 0) {
      alert("CSV file is empty");
      return;
    }
    setInputKey(Math.random().toString(36));
    console.log(data)
    // let _data = JSON.stringify(data);
    // let res = await admin_api.post(url, { data: _data });
    // res = res.data;
    // if (res.error) {
    //   alert(res.message);
    //   return;
    // }
    // tableRef.current && tableRef.current.onQueryChange();
  };

  const [fileName, setFileName] = useState("");
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    setFileName(file.name);

    const reader = new FileReader();
    reader.onload = async(e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });

      // Get the first sheet name
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];

      // Convert sheet to JSON
      const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1, defval: "" });

      // Convert headers to lowercase and replace spaces with "_"
      const headers = jsonData[0].map((header) =>
        header.toLowerCase().replace(/\s+/g, "_")
      );

      // Format data while ensuring empty values are kept as ""
      const formattedData = jsonData.slice(1).map((row) =>
        Object.fromEntries(
          headers.map((header, index) => [header, row[index] !== undefined ? row[index] : ""])
        )
      );
      console.log(formattedData);
      // onDataImport(formattedData); // Send data to parent component
      let _data = JSON.stringify(formattedData);
      let res = await admin_api.post(url, { data: _data });
      res = res.data;
      if (res.error) {
        alert(res.message);
        return;
      }
      tableRef.current && tableRef.current.onQueryChange();
    };

    reader.readAsArrayBuffer(file);
  };

  return (
    <div className="table">
      <div className="attend">
        <Typography variant="h5">
          Attendance rate: {percent ? percent : 0}%
        </Typography>
      </div>

      <div className="autoRefresh">
        <Switch
          name="gilad"
          color="primary"
          checked={autoRefresh}
          onChange={() => setAutoRefresh((prev) => !prev)}
        />
        <p>Auto Refresh</p>
      </div>

      {config &&
        <MaterialTable
          tableRef={tableRef}
          title={<img src={thumbnail_img} width="200px" />}
          editable={
            hp.isFull() == 0
              ? {} // Disable all editable features
              : {
            onRowAdd: (newData) =>
              new Promise((resolve, reject) => {
                const body = {
                  ...newData,
                  admitted_at: newData.admitted_at ? moment(newData.admitted_at).format(
                    "yyyy-MM-DD hh:mm:ss"
                  ) : null,
                };
                const query = `${url}/create`;
                const response = admin_api.post(query, body);
                tableRef.current && tableRef.current.onQueryChange();
                resolve();
              }),
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve, reject) => {
                console.log(newData)
                const body = {
                  ...newData,
                  admitted_at: newData.admitted_at ? moment(newData.admitted_at).format(
                    "yyyy-MM-DD hh:mm:ss"
                  ) : null,
                };
                const query = `${url}/update/${oldData.id}`;
                const response = admin_api.put(query, body);
                tableRef.current && tableRef.current.onQueryChange();
                resolve();
              }),
            onRowDelete: (oldData) =>
              new Promise((resolve, reject) => {
                const query = `${url}/delete/${oldData.id}`;
                const response = admin_api.delete(query);
                tableRef.current && tableRef.current.onQueryChange();
                resolve();
              }),
          }}
          columns={[
            { title: "Name", field: "name" },
            { title: "ID", field: "identity" },
            { title: "Contact No.", field: "phone" },
            { title: config.detail_1, field: "seat" },
            { title: config.detail_2, field: "session" },
            { title: config.detail_3, field: "guest_type" },
            { title: config.detail_4, field: "detail_3" },
            { title: config.detail_5, field: "detail_5" },
            { title: "Attendance", field: "is_admitted", type: "boolean" },
            { title: "Time Admitted", field: "admitted_at", type: "datetime" },
            { title: "Counter", field: "client" },
          ]}
          options={{
            // pageSize: 10,
            // pageSizeOptions: [10, 20, 50, 100, 300, 1000, total],
            pageSize: total,
            pageSizeOptions: [total],
            actionsColumnIndex: -1,
            sorting: true,
            addRowPosition: "first",
            exportButton: true,
            exportFileName: `${session_name}_${format(
              new Date(),
              "yyyyLLdd_kkmm"
            )}`,
            // exportCsv: () => {
            //   console.log('export')
            // }
          }}
          data={(query) =>
            new Promise(async (resolve) => {
              let q = `${url}?limit=${query.pageSize}&offset=${query.page * query.pageSize
                }`;
              if (query.orderBy) {
                q += `&orderBy=${query.orderBy?.field}&orderDirection=${query.orderDirection}`;
              }
              if (query.search != "") {
                q += `&search=${query.search}`;
              }
              const result = await admin_api.get(q);
              const { data } = result;
              setTotal(data.total);
              resolve({
                data: data.data,
                page: query.page,
                totalCount: data.total,
              });
            })
          }
          actions={[
            {
              icon: "refresh",
              tooltip: "Refresh Data",
              isFreeAction: true,
              onClick: () => tableRef.current && tableRef.current.onQueryChange(),
            },
            {
              icon: "publish",
              tooltip: "Upload New List",
              isFreeAction: true,
              hidden: hp.isFull() == 0,
              onClick: async () => {
                let result = await admin_api.get(`${url}`);
                let { data } = result;
                if (data.total != 0) {
                  alert("Please clear the existing data first");
                  return;
                }
                document.getElementById("react-csv-reader-input").click();
              },
            },
            {
              icon: "clear",
              tooltip: "Clear Existing List",
              isFreeAction: true,
              hidden: hp.isFull() == 0,
              onClick: async () => {
                let c = window.confirm("Please confirm to clear");
                if (c) {
                  let res = await admin_api.delete(`${url}`);
                  setPercent(0);
                  tableRef.current && tableRef.current.onQueryChange();
                }
              },
            },
          ]}
        />

      }
      <div key={input_key || ""}>
        <input id="react-csv-reader-input" className="react-csv-input" type="file" accept=".xlsx, .xls" onChange={handleFileUpload} />
      </div>
    </div>
  );
}

export default Page;
